import React from 'react';
import Navigation from '../components/navigation';
import SampleProjects from '../components/Projects/SampleProjects';
import Footer from '../components/footer';

export default class Projects extends React.Component {
  render() {
    return (
      <Navigation>
        <SampleProjects />
        <Footer />
      </Navigation>
    );
  }
}
