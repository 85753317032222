import React from 'react';
import styled from 'styled-components';
import { TitleH3 } from '../../styles/Theme';

const RepoLink = styled.a`
  color: rgb(1, 14, 30);
  :hover {
    color: #666666;
    text-decoration: none;
  }
`;

const Content = styled.div`
  margin-top: 5rem;
`;

const RepoButton = styled.button`
  border: 0;
  padding: 0.5rem;
  border-bottom: 2px solid rgb(1, 14, 30);
  border-top: 2px solid rgb(1, 14, 30);
  :hover {
    border-bottom: 2px solid #666666;
    border-top: 2px solid #666666;
  }
`;

export const Project = props => {
  const { item } = props;

  return (
    <Content>
      <div>
        <div>
          <TitleH3 className="text-center">{item.label}</TitleH3>
          <p className="lead text-center">{item.description}</p>
        </div>
        <div className="text-center">
          <RepoButton>
            <RepoLink
              href={item.repo}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>View Repository</div>
            </RepoLink>
          </RepoButton>
        </div>
      </div>
    </Content>
  );
};
