import React, { useEffect } from 'react';
import Slider from 'react-slick';
import { projects } from '../../constants/Projects';
import { Project } from './Project';
import { Heading } from '../../styles/Theme';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CustomSlider = styled(Slider)`
  .slick-slide {
    div {
      outline: none;
    }
  }
  .slick-prev:before {
    color: ${props =>
      props.theme.secondary ? props.theme.container : undefined};
  }
  .slick-next:before {
    color: ${props =>
      props.theme.secondary ? props.theme.container : undefined};
  }
`;

const ProjectContainer = styled.div`
  padding: 3rem 10rem;
  color: ${props =>
    props.theme.secondary ? props.theme.container : undefined};
  @media (max-width: 1100px) {
    padding: 3rem;
  }
`;

const Container = styled.div`
  padding: 3rem 10rem;
  background-color: ${props =>
    props.theme.secondary ? props.theme.carousel : undefined};
  @media (max-width: 1100px) {
    padding: 3rem;
  }
`;

const SampleProjects = () => {
  const [index, setIndex] = React.useState(0);
  const [renderSlider, setRenderSlider] = React.useState(false);
  const settings = {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    center: true,
    afterChange: ind => setIndex(ind),
  };

  useEffect(() => {
    setRenderSlider(true);
  }, []);
  return (
    <div>
      <ProjectContainer>
        <Heading className="text-center">Projects</Heading>
        <p className="lead text-center">
          I love writing code and express myself through my projects.
          <br />
          Here is a sample of what I've done so far.
        </p>
      </ProjectContainer>
      {renderSlider && (
        <Container>
          <CustomSlider {...settings}>
            {projects.map(item => {
              return <img src={item.src} key={item.index} alt="project" />;
            })}
          </CustomSlider>
          <Project item={projects[index]} />
        </Container>
      )}
    </div>
  );
};

export default SampleProjects;
