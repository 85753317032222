import newsApp from '../../pictures/newsApp.png';
import converter from '../../pictures/converter.png';
import weatherApp from '../../pictures/weatherApp.png';

export const projects = [
  {
    index: 0,
    label: 'Weather-App',
    description:
      'This application provides a five-day forecast for any inserted city, displaying the basic statistics for those forecasts for each day (min, max, mean temperatures). This project was developed using React, and styled with Bootstrap 4. Data for the forecasts was retrieved using OpenWeatherMap API.',
    src: weatherApp,
    repo: 'https://github.com/imourtz/open-weather-app',
    backgroundColor: '#2a2c45',
    color: '#fff',
  },
  {
    index: 1,
    label: 'News-App',
    description:
      'Using three APIs from newsapi.org I built a news web application. The user can see the latest news, navigate through different categories of news (Business, Tech, All), as well as enter keywords that filter the news accordingly. This web app is built with ReactJS.',
    src: newsApp,
    repo: 'https://github.com/ioannamourt/news-app',
    backgroundColor: '#fff',
    color: '#000',
  },
  {
    index: 2,
    label: 'HEX to RGB Converter',
    description:
      'A web application that the user inserts a HEX code value and if it is valid, it converts it to RGB code value. It contains some demo values and stores the converted values using local storage. To develop this web application, I utilised my skills in React.Js for its structure as well as Bootstrap 4 for styling purposes.',
    src: converter,
    repo: 'https://github.com/ioannamourt/hex-to-rgb-converter',
    color: '#fff',
    backgroundColor: 'rgb(1, 14, 30)',
  },
];
